<template>
    <div>
        <div class="d-flex align-center border-1 pa-0">
            <span
                v-if="subjects"
                class="d-flex align-center w-50 pa-3"
                :style="`border-right: 1px solid ${$const.color.extraLightGrey}`"
            >
                <selector
                    v-model="filter.subject"
                    :items="subjects"
                    class="w-100"
                    style="height: 40px;"
                    label="Предмет"
                />
            </span>
            <span v-if="grades" class="d-flex align-center w-50 pa-3">
                <selector
                    v-model="filter.grade"
                    :items="grades"
                    class="w-100"
                    style="height: 40px;"
                    label="Параллель"
                />
            </span>
        </div>
        <v-expansion-panels v-if="groupsByPickedParallel.length" :key="`${filter.grade}_${filter.subject}`" multiple class="mt-6">
            <v-expansion-panel
                v-for="group in groupsByPickedParallel"
                :key="group.id"
                class="v-expansion-panel"
            >
                <v-expansion-panel-header class="fw-500 py-0" style="min-height: 50px;">
                    Класс {{ group.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <group-form
                        v-for="(event, index) in events.filter(e => e.group_id === group.id)"
                        :key="index"
                        :model="event"
                        :check-date="checkDate"
                    />
                    <v-btn
                        outlined
                        height="40px"
                        class="button-stylized mt-5"
                        @click.prevent.stop="createEvent(group)"
                    >
                        Добавить мероприятие
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div v-else class="color-font-grey mt-4">Классов в указанной параллели не найдено.</div>
    </div>
</template>

<script>
import retrieveOrLoadMixin from '@/mixins/retrieveOrLoadMixin'
import GroupForm from './GroupForm.vue'
import Selector from '@/components/template/Selector.vue'

export default {
    components: { GroupForm, Selector },
    mixins: [ retrieveOrLoadMixin ],
    props: {
        subjects: { type: Array, default: () => [] },
        checkDate: { type: Function, default: (() => true) }
    },
    data () {
        return {
            events: [],
            filter: {
                subject: null,
                grade: null
            }
        }
    },
    computed: {
        groups () {
            return this.$store.state.group.items
        },
        grades () {
            return [4, 5, 6, 7, 8, 10].map(v => {
                return {
                    text: `${v} параллель`,
                    value: v
                };
            })
        },
        groupsByPickedParallel () {
            return this.groups.filter(g => g.grade === this.filter.grade)
        }
    },
    created () {
        this.retrieveOrLoad({ module: 'group', action: 'fetch', fields: 'grade,name,student_id' })
    },
    methods: {
        createEvent (group) {
            this.events.push({
                ...this.filter,
                group_id: group.id
            })
        }
    }
}
</script>