<template>
    <div class="d-flex align-center">
        <date-picker-field
            v-model="$v.form.dateRange.$model"
            :check-date="checkDate"
            :error-messages="getErrors('form.started_at') || getErrors('form.ended_at')"
            @input="onDatePickerUpdated"
        />
        <v-select
            v-model="$v.form.execution.$model"
            :error-messages="getErrors('form.execution')"
            required
            :items="executionTypes"
            :color="$const.color.primary"
            label="Формат проведения"
            class="ml-5"
        ></v-select>
        <v-btn
            outlined
            height="40px"
            :disabled="!!form.id"
            :loading="waiting.save.update"
            class="button-stylized-blue ml-5"
            @click.prevent.stop="save"
        >
            {{ form.id ? 'Создано' : 'Создать' }}
        </v-btn>
    </div>
</template>

<script>
import DatePickerField from '@/components/ui/DatePickerField.vue'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    components: {
        DatePickerField
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    props: {
        model: { type: Object, default: null },
        checkDate: { type: Function, default: (() => true) }
    },
    data () {
        return {
            storeModule: 'calendar_event',
            form: {
                id: null,
                dateRange: [],
                started_at: null,
                ended_at: null,
                category: 'test',
                subject: null,
                grade: null,
                group_id: null,
                execution: 'print'
            }
        }
    },
    validations () {
        return {
            form: {
                dateRange: {},
                started_at: { required },
                ended_at: { required },
                category: { required },
                subject: { required },
                grade: { required },
                execution: { required }
            }
        }
    },
    computed: {
        executionTypes () {
            return [
                { text: 'На бумажном носителе', value: 'print' },
                { text: 'С использованием компьютера', value: 'digital' }
            ]
        }
    },
    created () {
        if (this.model) {
            Object.keys(this.model).forEach(field => {
                this.form[field] = this.model[field]
            })
        }

        this.$on('success', this.onFormSucceed)
    },
    beforeDestroy () {
        this.$off('success', this.onFormSucceed)
    },
    methods: {
        onFormSucceed (payload) {
            this.form.id = payload.id
            this.$root.$emit('snack-bar', { text: 'Мероприятие успешно создано'})
        },
        onDatePickerUpdated () {
            this.form.started_at = this.form.dateRange[0]
            this.form.ended_at = this.form.dateRange[1]
        }
    }
}
</script>